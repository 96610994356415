import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from '../styles/theme';
import Button from './Button';
import BackgroundImage from 'gatsby-background-image'

const StyledWrapper = styled(BackgroundImage)`
  height: 315px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  z-index: 1;
  &:after {
    transition: .5s ease;
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-size: cover;
    filter: grayscale(100%);
    z-index: -1;
  }
  &:hover {
    &:after {
      filter: grayscale(100%) blur(8px) brightness(60%);
    }
  }
  ${({ theme }) => theme.mq.s} {
    height: 290px;
  }
  ${({ theme }) => theme.mq.xs} {
    height: 255px;
  }
  ${({ theme }) => theme.mq.xxs} {
    height: 230px;
  }
`;

const StyledName = styled.h3`
  text-transform: uppercase;
  position: absolute;
  font-size: 1.35rem;
  letter-spacing: 2px;
  font-weight: 500;
  top: 80%;
  left: 1.5rem;
  display: inline-block;
  padding: 1rem;
  background: ${({ theme }) => theme.color.grayGreen};
  transition: .3s ease;
  opacity: 1;
  transform: translate(0px, 0px);
  ${StyledWrapper}:hover & {
    opacity: 0;
    transform: translate(-200px, 10px);
  }
`;

const StyledNameHover = styled.h3`
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 2px;
  width: 90%;
  top: 5%;
  left: 2.5%;
  color: ${({ theme }) => theme.color.white};
  font-weight: 700;
  padding: 5px;
  text-align: center;
  background-color: ${({ theme }) => hexToRgba(theme.color.grayLighter, 0.7)};
  transition: .3s ease;
  opacity: 0;
  transform: translate(20px, -100px);
  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StyledTable = styled.div`
  color: ${({ theme }) => theme.color.white};
  display: grid;
  grid-column-gap: 3.5rem;
  grid-row-gap: 1rem;
  text-transform: uppercase;
  width: unset;
  justify-self: left;
  justify-content: start;
  grid-template-columns: auto auto;
  font-weight: 600;
  font-size: 1.4rem;
  span {
    text-align: right;
  }
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  top: 75%;
  transition: .3s ease;
  opacity: 0;
  transform: translate(350px, -10px);
  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`;

const StyledKey = styled.span`
  transition: .35s ease-out;
  transition-delay: ${({ i }) => `${i * 0.05}s`};
  opacity: 0;
  transform: translateX(-200px);
  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const StyledValue = styled.span`
  transition: .35s ease-out;
  transition-delay: ${({ i }) => `${i * 0.05}s`};
  opacity: 0;
  transform: translateX(200px);
  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const ModelTile = ({
                     name, fluid, slug, sizes,
                   }) => (
  <StyledWrapper style={{filter: 'grayscale(100%)'}} fluid={fluid}>
    <StyledName>{name}</StyledName>
    <StyledNameHover>{name}</StyledNameHover>
    <StyledTable>
      {!!sizes.height && (
        <>
          <StyledKey i={0}>height</StyledKey>
          <StyledValue i={4}>{sizes.height}</StyledValue>
        </>
      )}
      {!!sizes.bust && (
        <>
          <StyledKey i={1}>bust</StyledKey>
          <StyledValue i={3}>{sizes.bust}</StyledValue>
        </>
      )}
      {!!sizes.waist && (
        <>
          <StyledKey i={2}>waist</StyledKey>
          <StyledValue i={2}>{sizes.waist}</StyledValue>
        </>
      )}
      {!!sizes.hips && (
        <>
          <StyledKey i={3}>hips</StyledKey>
          <StyledValue i={1}>{sizes.hips}</StyledValue>
        </>
      )}
    </StyledTable>
    <StyledButton>
      <Button to={`/models/${slug}`} component="link" color="light" inverted>PORTFOLIO</Button>
    </StyledButton>
  </StyledWrapper>
);

export default ModelTile;
