import React, {useMemo} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Layout from 'components/layout/Layout';
import ModelTile from 'components/ModelTile';
import Container from 'components/containers/Container';
import Grid from 'components/containers/Grid';
import SEO from "components/Seo";

const StyledGrid = styled(Grid)`
  max-width: 100%;
`;

const StyledGallery = styled.div`
  //padding-top: 40px;
`

const StyledTitle = styled.h2`
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
`

const Main = ({data, location}) => {
  const models = useMemo(() => {
    return data.allDatoCmsModelEntity.edges
      .sort((a, b) => ( a.node.position - b.node.position ))
      .map(({node}) => {
        const {
          name, slug, height, waist, bust, hips, mainImage, newFace,
        } = node;
        return  {
          name,
          slug,
          imageUrl: mainImage.url,
          fluid: mainImage.imageFile.childImageSharp.fluid,
          sizes: {
            height, waist, bust, hips,
          },
        };
      })
  }, []);
  return (
    <Layout location={location}>
      <SEO title="MODELS"/>
      <Container size="l">
        <StyledGallery>
          {/*<StyledTitle>#onmovemodels</StyledTitle>*/}
          <StyledGrid
            customL={1180}
            cols="repeat(5, 1fr)"
            colsL="repeat(4, 1fr)"
            colsM="repeat(3, 1fr)"
            colsS="repeat(2, 1fr)"
            gap="35px"
            padding="40px 10px 0 10px"
          >
            {models.map(model => (
              <ModelTile
                key={model.slug}
                {...model}
              />
            ))}
          </StyledGrid>
        </StyledGallery>
      </Container>
    </Layout>
  );
};

export default Main;

export const query = graphql`
    query AllMainModels {
        allDatoCmsModelEntity(filter: {newFace: {eq: false}}) {
            edges {
                node {
                    id
                    position
                    slug
                    height
                    waist
                    bust
                    name
                    hips
                    mainImage {
                        imageFile {
                            childImageSharp {
                                fluid {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                        url
                    }
                }
            }
        }
    }
`;
